/*package CSS*/
@import 'react-phone-input-2/lib/style.css';

/*variables*/
$black-color : #000;
$white-color : #FFF;
$grey-color : #CCC;

$main-color : #002858;
$cta-color : #046BE8;

$background-color : #F9F9FA;
$background2-color : #FAFAFA;

//profil color
$profil-recruteur-color : #002858;
$profil-recruteur-color2 : #EDF4FF;
$profil-recruteur-color3 : #C0D9FD;
$profil-recruteur-color4 : #006AEA;

$profil-candidat-color : #D6B664;
$profil-candidat-color2 : #F2ECDD;
$profil-candidat-color3 : #D6B664;
$profil-candidat-color4 : #D6B664;

//bloc generic
$generic_bloc_main_color : $main-color;
$generic_bloc_secondary_color : #757575;

//form
$form-input-border : #dcdcdc;
$form-separator-color : #121212;

$form-style2-border : #DFDFDF;
$form-style2-label : #3E435D;

$form-style3-border : #dcdcdc;
$form-style3-label : #757575;

$form-upload-profil : #E8EEF4;
$form-upload-color : #6C7C93;
$form-upload-disabled : #D9D9D9;

//jumbotron
$jumbotron-bg-yellow : #FFF0D8;

//Dashboard
$dashboard-block-bg : #FFFFFF;
$dashboard-block-border : #F2F2F2;

//CTA
$cta-blue-bg : #006AEA;
$cta-blue-color : #FFFFFF;

$cta-disabled-blue-bg : transparent;
$cta-disabled-blue-border : #D9D9D9;
$cta-disabled-blue-color : #D9D9D9;

/// button bar
$buttonbar-active-bg : #006AEA;
$buttonbar-active-color : #002858;

$buttonbar-inactive-bg : #E6F3FF;
$buttonbar-inactive-color : #757575;

//label
$label-bg : #EDF4FF;
$label-color : #78AAF4;

//sliding panel
$sliding-panel-bg : #FFFFFF;

//skills
$skills-color :#734CC9;

//availability
$availability-wrapper-bg : #F7F9FA;

//questions
$question-stat-color : #6F7F95;
$question-stat-cat-bg : #ECFAF8;
$question-stat-cat-color : #72C6BC;
$question-stat-cat-more-bg : #E6F3FF;
$question-stat-cat-more-color : #006AEA;

//notification
$mission_notification_match : #3A9186;
$mission_notification_match_bg : #D6F4F0;

$mission_notification_new_match : #3A9186;
$mission_notification_new_match_bg : #D6F4F0;

$mission_notification_its_a_match : #D6B664;
$mission_notification_its_a_match_bg : #F4ECD8;

//match
$match_action_label_color : #006AEA;
$match_block_border_color : #DFDFDF;

//dropzone
$dropzone_notification_success : #002858;

//Block mission
$block-mission-secondary-color : #7F909F;

//Block Help
$block-help-date-color : #006AEA;
$block-help-subtitle-color : #7F909F;
$block-help-urgent-color : #9F3A05;
$block-help-urgent-color-bg : #EB996E;


//Chat Color
$chat-separator-color : #a7a7a7;

$chat-message-incoming-bg : #e8e8e8;
$chat-message-incoming-color : #333333;
$chat-message-outgoing-bg : #397bf4;
$chat-message-outgoing-color : #FFFFFF;

$chat-input-bg-color : #ffffff;

//Subscriptiosn color
$subscription_block_active_border : #046BE8;
$subscription_block_border : #D9D9D9;

$subscription_block_title : #002858;
$subscription_block_description : #046BE8;
$subscription_block_detail : #757575;


//notification
$notification-bg : #EDF4FF;
$dot-red : #E31B23;

/*function*/
$mobile_base_size: 375;
//$mobile_base_size: 400;
$desktop_base_size : 1440;

@function get-desktop-vw($size){

  @if(index('px', unit($size)) == null){
    $size : $size * 1px;
  }

  $vw-context: ($desktop_base_size*.01) * 1px;
  @return ($size/$vw-context) * 1vw;
}

@function get-tab-vw($size){

  @if(index('px', unit($size)) == null){
    $size : $size * 1px;
  }

  $vw-context: ($tab_base_size*.01) * 1px;
  @return ($size/$vw-context) * 1vw;
}

@function get-mobile-vw($size){

  @if(index('px', unit($size)) == null){
    $size : $size * 1px;
  }

  $vw-context: ($mobile_base_size*.01) * 1px;
  @return ($size/$vw-context) * 1vw;
}

@function get-mobile-landscape-vw($size){

  @if(index('px', unit($size)) == null){
    $size : $size * 1px;
  }

  $vw-context: ($mobile_landscape_base_size*.01) * 1px;
  @return ($size/$vw-context) * 1vw;
}
