@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Manrope:wght@200;300;400;500;600;700;800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Ribeye+Marrow&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap');

.ff-poppins{ font-family: 'Poppins', sans-serif; }
.ff-inter{ font-family: 'Inter', sans-serif; }
.ff-manrope{ font-family: 'Manrope', sans-serif; }
.ff-ribeye{ font-family: 'Ribeye Marrow', sans-serif; }
.ff-nunito{ font-family: "Nunito Sans", sans-serif; }

.fw-thin{ font-weight: 300; }
.fw-regular{ font-weight: 400; }
.fw-medium{ font-weight: 500; }
.fw-semibold{ font-weight: 600; }
.fw-bold{ font-weight: bold; }
.fw-extrabold{ font-weight: 800; }
.fw-black{ font-weight: 900; }

.fs-uppercase{ text-transform: uppercase; }
.fs-underline{ text-decoration: underline; }
.fs-italic{ font-style: italic; }
