@import 'global';
@import 'font';

@function get-vw($size) {
  @if(index('px', unit($size)) == null){
    $size : $size * 1px;
  }

  @return $size;
}


@media screen and (min-width: 768px){

  $content-x-padding : get-vw(20px);
  $content-y-padding : 0;

  $base-font-size : get-vw(12px);

  html{
    font-size : $base-font-size;
  }

  body{

    .page_wrapper {
      background-color : $background-color;
      //max-width: 375px;
      width: 100%;
      margin: auto;

      min-height: 100vh;
      display: flex;
      flex-direction: column;

      /*background-color : $background2-color;*/
      padding-bottom: get-vw(50px);

      --profil-color : #{$profil-recruteur-color};
      --profil-color-reverse : #{$profil-candidat-color};
      --profil-color-reverse2 : #{$profil-candidat-color2};

      &[data-profil="0"]{
        --profil-color : #{$profil-recruteur-color};
        --profil-color-reverse : #{$profil-candidat-color};
        --profil-color-reverse2 : #{$profil-candidat-color2};
        --profil-color2 : #{$profil-recruteur-color2};
        --profil-color3 : #{$profil-recruteur-color3};
        --profil-color4 : #{$profil-recruteur-color4};
      }
      &[data-profil="1"]{
        --profil-color : #{$profil-recruteur-color};
        --profil-color-reverse : #{$profil-recruteur-color};
        --profil-color-reverse2 : #{$profil-recruteur-color2};
        --profil-color2 : #{$profil-candidat-color2};
        --profil-color3 : #{$profil-candidat-color3};
        --profil-color4 : #{$profil-candidat-color4};
      }

    }

    .main {
      display: flex;
      flex-direction: row;
    }

    .sidebar-left {
      width: 500px;
      height: 100vh;
      min-height: 100vh;
      background: $profil-recruteur-color;
      padding: 30px;
      position: relative;

      .logo {
        position: absolute;
        background-image: url("../images/mobile/header_logo_white.png");
        background-size: 100% auto;
        background-repeat: no-repeat;
        width: 260px;
        height: 100px;
        left: 50%;
        transform: translateX(-50%);
        margin: auto;
      }

      p {
        font-family: 'Inter', sans-serif;
        font-size: 28px;
        color: $white-color;

        position: absolute;
        bottom: 50px;
      }
    }

    .content_wrapper{
      flex-grow: 1;
      padding: $content-y-padding $content-x-padding;

      width: 100%;
      max-width: 1900px;
      margin: auto;

      font-size : 1rem;

      &_vertically_centered{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }

    }

    //autre
    .react-datepicker-wrapper{
      width: 100%;
    }

    //global
    a{
      color : inherit;
    }

    //title
    h1{


      color : $main-color;
      font-size : 2rem;
      margin-bottom : get-vw(20px);

      &+p{
        margin-top: get-vw(-10px);
        margin-bottom: get-vw(30px);

        color : $generic_bloc_secondary_color;
        font-size : 1.2rem
      }

    }

    h2{
      color : $main-color;
      font-size: 24px;

      line-height: 2rem;
      margin-bottom : get-vw(11px);
    }

    h3{
      color : $main-color;
      font-size: 1.16rem;

      margin-bottom : get-vw(8px);
      line-height: 1.75rem;
    }

    h4{
      color : $main-color;
      font-size: get-vw(12px);

      margin-bottom : get-vw(15px);
    }

    h5{
      color: $main-color;
      font-size: 1rem;
      margin-bottom : get-vw(15px);
    }

    //cta
    .cta{
      display: inline-block;
      border: 0;
      background-color: transparent;
      outline: 0;
      cursor: pointer;

      &.small{

        border-radius: get-vw(5px);
        text-align: center;
        font-size: get-vw(10px);
        line-height: normal;

        padding: get-vw(7px) get-vw(13px);
      }

      &.full{
        border-radius: get-vw(6px);
        text-align: center;
        font-size: 1.3rem;
        line-height: 36px;

        padding: 10px 10px;
        width: 100%;

        margin-bottom: 15px;
      }

      &.blue{
        background: $cta-blue-bg;
        color: $cta-blue-color;

        &.reverse{
          background: $cta-blue-color;
          color: $cta-blue-bg;
          border : get-vw(1px) solid $cta-blue-bg;
        }
      }

      &.transparent{
        background: transparent;
        color: $cta-blue-bg;
      }

      &.dot_more{
        background-image : url("../images/mobile/cta_dot_more.svg");
        background-repeat: no-repeat;
        background-size: get-vw(2.2px) auto;
        background-position: center;

        width: get-vw(10px);
        height: get-vw(10px);
      }

      &.return{
        background-image : url("../images/mobile/arrow.svg");
        background-repeat: no-repeat;
        background-size: get-vw(24px) auto;
        background-position: center;

        width: get-vw(42px);
        height: get-vw(42px);

        border: get-vw(1px) solid $grey-color;
        border-radius: 100%;
      }

      &.bookmark{
        background-image : url("../images/mobile/match_like.svg");
        background-repeat: no-repeat;
        background-size: get-vw(24px) auto;
        background-position: center;

        width: get-vw(42px);
        height: get-vw(42px);

        border: get-vw(1px) solid $grey-color;
        border-radius: 100%;

        &.active{
          background-image : url("../images/mobile/match_like_active.svg");
        }
      }

      &.like{
        background-size: get-vw(24px) auto;

        width: get-vw(42px);
        height: get-vw(42px);

        border: get-vw(1px) solid $grey-color;

        &.active{
          border: get-vw(1px) solid $cta-color;
        }
      }

      &.comment{
        background-size: get-vw(24px) auto;

        width: get-vw(42px);
        height: get-vw(42px);

        border: get-vw(1px) solid $grey-color;
      }

      &.message{
        background-image : url("../images/mobile/message.svg");
        background-repeat: no-repeat;
        background-size: get-vw(40px) auto;
        background-position: center;

        width: get-vw(60px);
        height: get-vw(60px);

        border: get-vw(1px) solid $grey-color;
        border-radius: 100%;
      }

      &.disabled{
        cursor: default;
      }

      &.social_login{
        border : get-vw(1px) solid $form-input-border;
        border-radius: get-vw(8px);

        margin-bottom: get-vw(15px);

        background-color : $white-color;
        height: get-vw(56px);
        /*max-width: get-vw(150px);*/
        width: 100%;

        font-size : get-vw(16px);
        color : $black-color;



        span{
          display: inline-block;
          background-size : get-vw(19px) auto;
          background-position: left center;
          background-repeat: no-repeat;

          padding-left: get-vw(25px);
        }

        &.google span{
          background-image : url("../images/mobile/cta_connect_google.svg");
        }
        &.linkedin span{
          background-image : url("../images/mobile/cta_connect_linkedin.svg");
        }
      }

      &.profile_switch {
        max-width: 500px;
        line-height: 37px;
        text-decoration: none;
        color: var(--profil-color-reverse);
        background: var(--profil-color-reverse2);
        border : get-vw(1px) solid var(--profil-color-reverse2);
        border-radius: 5px;
        padding: 0px 10px;
        font-size: 1.1rem;
        font-weight: 500;
      }
    }

    //picto
    .picto{

      &:is(span){
        display: inline-block;
        width: get-vw(20px);
        height: get-vw(20px);

        background-size : 100% auto;
        background-repeat: no-repeat;
      }

      &:is(div){
        background-size : get-vw(20px) auto;
        background-position: left center;
        background-repeat: no-repeat;

        padding-left: get-vw(24px);
      }

      &.bar{background-image : url("../images/mobile/picto_bar.svg");}
      &.location{background-image : url("../images/mobile/picto_location.svg");}
      &.language{background-image : url("../images/mobile/picto_language.svg");}
      &.experience{background-image : url("../images/mobile/picto_experience.svg");}
    }

    //form
    form,
    div.form{

      .form_password_strength{
        > div{
          border-radius: get-vw(2px);
          overflow: hidden;

          //spacer
          > div:nth-of-type(even){
            display: none;
          }

          //jauge
          > div:nth-of-type(odd){
            height: get-vw(4px) !important;
          }
        }
      }

      .form_row_wrapper{
        margin-bottom : get-vw(15px);

        &.type1{
          input[type="text"],
          input[type="email"],
          input[type="password"],
          select{
            background-color : $white-color;
            height: get-vw(56px);
            border: get-vw(1px) solid $form-input-border;
            border-radius: get-vw(8px);
            padding: 0 get-vw(15px);
            width: 100%;
          }

          input[type="submit"]{
            display: inline-block;
            height: get-vw(56px);
            line-height: get-vw(56px);

            background-color : $cta-color;
            border-radius: get-vw(8px);
            border: 0;
            outline: 0;

            text-align: center;
            font-size : get-vw(16px);

            color : $white-color;
            width: 100%;

            cursor: pointer;

            &.submit-big {
              height: get-vw(66px);
            }
          }
        }

        &.type2{
          position: relative;
          margin-bottom : get-vw(7px);

          label{
            position: absolute;

            color: $form-style2-label;
            font-size: get-vw(10px);

            line-height: get-vw(10px);

            top: get-vw(3px);
            left : get-vw(11px);
          }

          input[type="text"],
          input[type="email"],
          input[type="password"],
          select,
          div.dropzone{
            width: 100%;
            height: get-vw(40px);
            border-radius: get-vw(5px);
            border: get-vw(1px) solid $form-style2-border;

            padding: get-vw(10px) get-vw(11px) get-vw(3px) get-vw(11px);
            background-color : transparent;
            font-size : get-vw(11px);
          }
          div.dropzone{
            display: flex;
            justify-content: center;
            align-items: center;
            height: get-vw(60px);
            text-align: center;
            cursor: pointer;

            font-weight: bold;

            padding: get-vw(10px) get-vw(11px);
          }

          &.multiselect {
            label{
              z-index: 9;
            }

            //multiselect wrapper
            > div{

              //fake input
              > div:first-of-type {
                border-radius: get-vw(5px);
                border: get-vw(1px) solid $form-style2-border;

                > div:first-of-type {
                  min-height: get-vw(30px);

                  padding-top: get-vw(15px);
                }
              }
            }

            input {
              height: auto;
            }
          }

          textarea{
            padding: get-vw(13px) get-vw(11px);
            font-size : get-vw(11px);

            width: 100%;
            min-height: get-vw(110px);

            border-radius: get-vw(5px);
            border: 1px solid $form-style2-border;
            background: #FFFFFF;

          }
        }

        &.type3{
          position: relative;
          margin-bottom : get-vw(15px);
          padding-top: get-vw(10px);

          label{
            position: absolute;
            z-index: 2;

            background-color : $white-color;
            color: $form-style3-label;
            font-size: 1rem;

            line-height: 1.5rem;

            padding: 0 get-vw(7px);

            top: get-vw(9px);
            left: get-vw(10px);
            transform: translateY(-50%);
          }

          input[type="text"],
          input[type="email"],
          input[type="password"],
          select,
          div.dropzone{
            width: 100%;
            height: get-vw(56px);
            border-radius: get-vw(8px);
            border: get-vw(1px) solid $form-style3-border;

            padding: 0 get-vw(16px);
            background-color : transparent;
            font-size : 1.3rem;
          }

          /*file drop input*/
          div.dropzone{
            cursor: pointer;
            padding: get-vw(12px) get-vw(20px) get-vw(12px) get-vw(90px);

            font-size : 1.08rem;
            border-color : $cta-color;
            color : $cta-color;

            height: auto;

            background-image : url("../images/mobile/carte_pro.svg");
            background-size : 27px auto;
            background-position: center left get-vw(30px);
            background-repeat: no-repeat;
          }

          /*phone input*/
          div.react-tel-input {
            .flag-dropdown{
              border: 0;
              background-color: transparent;
            }
            input {
              width: 100%;
              height: get-vw(56px);

              border-radius: get-vw(8px);
              border: get-vw(1px) solid $form-style3-border;
              background-color: transparent;
              font-size: 1.3rem;
            }
          }

          /*password input*/
          .inputPassword{
            position: relative;

            input{
              padding-right: get-vw(50px);
            }
            span{
              position: absolute;
              width: get-vw(24px);
              height: get-vw(24px);

              background-image: url("../images/mobile/eye_open.svg");
              background-repeat: no-repeat;
              background-size : 100% auto;

              right: get-vw(16px);
              top: 50%;

              transform: translateY(-50%);
              cursor: pointer;

              &.show{
                background-image: url("../images/mobile/eye_close.svg");
              }
            }
          }

          /* multiselect input */
          &.multiselect {
            label{
              z-index: 9;
            }

            //multiselect wrapper
            > div{

              //fake input
              > div:first-of-type {
                border-radius: get-vw(5px);
                border: get-vw(1px) solid $form-style2-border;

                > div:first-of-type {
                  min-height: get-vw(56px);

                  padding-top: 0;
                }
              }

              //menu
              > div:nth-of-type(2){
                z-index: 99;
              }
            }

            input {
              height: auto;
            }
          }

          textarea{
            padding: get-vw(13px) get-vw(11px);
            font-size : get-vw(11px);

            width: 100%;
            min-height: get-vw(110px);

            border-radius: get-vw(5px);
            border: 1px solid $form-style2-border;
            background: #FFFFFF;

          }
        }

        &.upload_wrapper{
          display: flex;
          flex-direction: row;
          align-items: center;

          input{
            display: none;
          }

          .picture{
            overflow: hidden;
            width: get-vw(100px);
            height: get-vw(100px);

            border-radius : 100%;
            background-color : $form-upload-profil;
            /*margin-right: get-vw(7px);*/
            margin: auto;

            img{
              object-fit: cover;
              width: 100%;
              height: 100%;
            }

            > div{
              font-size : get-vw(40px);
              text-align: center;
              line-height: get-vw(100px);
              text-transform: uppercase;

              background-color : var(--profil-color);
              color : $white-color;

            }

            &:hover {
              cursor: pointer;
            }

          }

          /*button,
          label{


            color: $form-upload-color;
            text-align: center;
            font-size: get-vw(10px);
            line-height: normal;

            border-radius: 5px;
            border: 1px solid #6C7C93;
            background: #FFF;

            padding : get-vw(7px) get-vw(10px);

            cursor: pointer;

            &+button{
              margin-left: get-vw(10px);
            }

            &:disabled{
              cursor: not-allowed;
              color : $form-upload-disabled;
              border-color : $form-upload-disabled;
            }
          }*/
        }

        &.dd_upload{
          label{
            min-width: 0;
          }
        }

        &.column{
          div+div{
            margin-top : get-vw(15px);
          }
        }

        &.dropzone_wrapper{

          .dropzone_notice{
            font-size : 1.5rem;
            padding: get-vw(5px) get-vw(10px);

            &.success{
              color: $dropzone_notification_success;

              &:after{
                content: "";
                display: inline-block;

                width: get-vw(13px);
                height: get-vw(13px);

                background-color : $dropzone_notification_success;
                border-radius: 100%;

                background-image : url("../images/mobile/check_icon_white.svg");
                background-repeat: no-repeat;
                background-position: top get-vw(4px) center;
                background-size : get-vw(8px) auto;

                margin-left: get-vw(5px);
              }
            }
          }

          .dropzone_files{
            padding-left: get-vw(10px);
            font-size : get-vw(14px);

            .dropzone_delete{
              cursor: pointer;
              font-weight: bold;
              color : red;
            }
          }

        }

      }

      .form_row_title{
        padding-top: get-vw(20px);
        margin-bottom : get-vw(15px);
      }

      .checkbox_wrapper{

        input{
          position: absolute;
          z-index: -999;
          opacity: 0;
          text-indent: -99999px;
        }

        &.checkbox_cta{

          input + label{
            display: inline-block;
            height: get-vw(56px);
            line-height: get-vw(56px);

            border: get-vw(1px) solid $form-input-border;
            border-radius: get-vw(8px);
            background-color : $white-color;

            text-align: center;
            font-size : get-vw(16px);

            width: 100%;

            cursor: pointer;

            transition: 0.3s;

            box-shadow: 0 get-vw(4px) get-vw(2px) 0 rgba(25, 119, 243, 0.09);
          }

          input:checked + label{
            //background-color : $cta-color;
            background-color : $white-color;
            border-color : $cta-color;
            //color : $white-color;
            color : $cta-color;
          }

        }

        &.checkbox_small_cta{

          text-align: center;

          input + label{
            display: inline-block;
            height: get-vw(30px);
            line-height: get-vw(30px);

            border: get-vw(1px) solid $cta-blue-bg;
            border-radius: get-vw(8px);

            text-align: center;
            font-size : get-vw(10px);
            color : $cta-blue-bg;

            width: 100%;
            max-width: get-vw(150px);

            cursor: pointer;

            transition: 0.3s;
          }

          input:checked + label{
            background-color : $cta-color;
            border-color : $cta-color;
            color : $white-color;
          }

        }

        &.checkbox{
          input + label{
            position: relative;
            display: inline-block;

            padding-left: get-vw(25px);
            padding-top: get-vw(3px);
            font-weight: bold;

            &:before{
              position: absolute;
              left: 0;
              top: 0;

              content : "";
              display: inline-block;

              width: get-vw(20px);
              height: get-vw(20px);

              border : 1px solid $main-color;
              border-radius: get-vw(3px);
            }
          }

          input:checked + label:before{
            background-color : $main-color;
          }
        }

        &.checkbox_blue{
          input + label{
            position: relative;
            display: inline-block;

            padding-left: get-vw(25px);
            padding-top: 0;

            &:before{
              position: absolute;
              left: 0;
              top: 0;

              content : "";
              display: inline-block;

              width: get-vw(20px);
              height: get-vw(20px);

              border : 1px solid $main-color;
              border-radius: get-vw(3px);
            }
          }

          input:checked + label:before{
            background-color : $main-color;
          }
        }
      }

      .error{
        font-size : get-vw(12px);
        color: red;
      }
    }

    .choice-header-img {
      height: 300px;
    }

    //jumbotron
    .jumbotron{

      border: 1px solid $profil-recruteur-color4;
      background: $profil-recruteur-color4;
      padding: get-vw(40px) get-vw(25px);
      text-align: center;
      margin-bottom: get-vw(20px);
      border-radius: get-vw(8px);
      color: $white-color;

      .jumbotron_title{
        margin-bottom : get-vw(20px);
        text-decoration: underline;
        font-size : get-vw(18px);
      }
      .jumbotron_content{
        font-size : get-vw(14px);
      }

      &.yellow{
        background-color : $jumbotron-bg-yellow;
      }
    }

    //align
    .text-right{
      text-align: right;
    }
    .text-center{
      text-align: center;
    }
    .text-left{
      text-align: left;
    }

    //display
    .d-flex {
      display: flex;
    }

    .d-block {
      display: block;
    }

    .d-desktop-none {
      display: none;
    }

    //marge
    .mb1{
      margin-bottom: 10px;
    }
    .mb2{
      margin-bottom: 20px;
    }
    .mb3{
      margin-bottom: 30px;
    }

    .mt1{
      margin-top: 10px;
    }
    .mt2{
      margin-top: 20px;
    }
    .mt3{
      margin-top: 30px;
    }

    /* Base Class */
    .generic_data_block {
      background-color : $dashboard-block-bg;

      border-radius: get-vw(20px);
      //border: get-vw(1px) solid $dashboard-block-border;

      padding: get-vw(12px);
      margin-bottom : get-vw(20px);

      color : $main-color;
      font-size: 1.12rem;


      cursor: pointer;

      .content{
        .description{

        }
        .date{

        }

        &.vcentered{
          justify-content: center;
          display: flex;
          flex-direction: column;
        }

      }
      .tags{
        padding: get-vw(5px) 0;

        display: flex;
        flex-wrap: wrap;
        flex-direction: row;

        gap: get-vw(5px);

        span {
          background: $label-bg;
          color: $label-color;

          text-align: center;

          padding: 0 get-vw(10px);
          line-height: get-vw(20px);
          height: get-vw(20px);
          font-size: get-vw(12px);

          border-radius: get-vw(6px);
        }
      }
      &.empty{
        text-align: center;
        cursor: auto;
        padding: get-vw(20px) get-vw(12px);
      }
    }
    .generic_data_wrapper {
      padding: get-vw(20px) 0;
    }
    .generic_data_block_picture {
      width: get-vw(54px);
      height: get-vw(54px);

      background-color : $grey-color;
    }
    .generic_data_block_label {
      //position: relative;

      //font-size : 2.5rem;
      //color : $main-color;
      //text-transform: uppercase;

      min-width: get-vw(54px);
      width: get-vw(54px);
      height: get-vw(54px);

      background-size: 100% auto;
      background-repeat: no-repeat;
      background-position: center;

      //span{
      //  position: absolute;
      //  left: get-vw(11px);
      //
      //  &:nth-of-type(1){
      //    top: get-vw(-5px);
      //  }
      //
      //  &:nth-of-type(2){
      //    top: get-vw(15px);
      //  }
      //}
    }

    /* CONNEXION / INSCRIPTION / MDP OUBLIE */
    .connection_wrapper {
      width: 100%;
      padding: get-vw(20px);

      .connection_box {
        margin: auto;
        max-width: 460px;
      }

      .connection_link{
        margin-bottom: get-vw(30px);
        font-size: 1.16rem;

        a{
          color : $main-color;

          text-decoration: none;

          &#connection_link_reset{
            text-align: right;
          }
        }
      }

      .separator {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        opacity: 0.5;
        margin-bottom : get-vw(15px);

        div {
          flex-grow: 1;
          background-color: $form-separator-color;
          height: get-vw(1px);
        }
        span {
          font-size: get-vw(12px);
          color: $form-separator-color;
          padding: 0 get-vw(5px);
        }
      }

    }

    /* PROTECTED PAGE */

    /* block dashboard */
    .dashboard_block_wrapper{
      position: relative;
      @extend .generic_data_wrapper;
      background: $white-color;
      border-radius: 15px;
      margin: 10px;
      padding: 30px;

      .dashboard_block_more_link{
        position: absolute;

        color : $cta-blue-bg;
        top: get-vw(20px);
        right: 50px;

        font-size : 1.16rem;
        text-decoration: none;
      }
      .dashboard_item{
        @extend .generic_data_block;

        &.dashboard_item_help,
        &.dashboard_item_switch{
          display: flex;
          flex-direction: row;
          align-items: center;
          gap : get-vw(10px);

          cursor: pointer;

          .picture{
            @extend .generic_data_block_picture;
          }

          .item_label{
            @extend .generic_data_block_label;
          }

        }
      }
      .conversation_wrapper {
        padding: 30px 0;

        .side-right {
          margin: 0;
        }
      }

      .user_picture {
        margin-bottom: get-vw(10px);
      }
    }

    /* block match */
    .match_block {
      @extend .generic_data_block;

      display: flex;
      flex-direction: row;
      gap : get-vw(14px);

      padding: get-vw(14px);

      .picture {
        @extend .generic_data_block_picture;
        border-radius: 100%;
        background-color : var(--profil-color3)
      }
      .content{
        flex-grow: 1;
      }
    }

    /* block collaborateur */
    .profil_talent_block{
      @extend .generic_data_block;

      display: flex;
      flex-direction: row;
      align-items: center;
      gap : get-vw(10px);

      .picture{
        @extend .generic_data_block_picture;
        border-radius: 100%;
        background-color : transparent;

        width: get-vw(40px);
        height: get-vw(40px);

        img {
          object-fit: cover;
          width: 100%;
          height: 100%;
        }

      }

      .content{
        flex-grow: 1;
      }
      .square{
        border-radius: get-vw(3px);
        background-color : $cta-blue-bg;

        width: get-vw(10px);
        height: get-vw(10px);
      }

    }

    /** DEPRECATED **/
    .dashboard_block{

      background-color : $dashboard-block-bg;

      border-radius: get-vw(10px);
      border: get-vw(1px) solid $dashboard-block-border;

      padding: get-vw(20px) get-vw(25px);
      margin-bottom : get-vw(20px);

      &.column{
        display: flex;
        flex-direction: row;
        gap : get-vw(20px);
      }

      &.detail_block{

        padding: get-vw(25px) get-vw(16px);

        .notification {



          font-size : get-vw(13px);
          text-align: center;

          border-radius : get-vw(5px);
          padding: get-vw(3px) get-vw(20px);
          margin-top: get-vw(-15px);
          margin-bottom: get-vw(15px);

          &.match{
            color : $mission_notification_match;
            background-color : $mission_notification_match_bg;
          }

          &.new_match{
            color : $mission_notification_new_match;
            background-color : $mission_notification_new_match_bg;
          }

          &.its_a_match{
            color : $mission_notification_its_a_match;
            background-color : $mission_notification_its_a_match_bg;
          }
        }

        .date{
          color: $cta-blue-bg;
          font-size: get-vw(12px);
          margin-bottom : get-vw(9px);
        }
        .title{
          color : $main-color;
          font-size: get-vw(14px);

          margin-bottom : get-vw(9px);
        }
        .description{
          color: $black-color;
          font-size: get-vw(10px);
          line-height: get-vw(18px);


          margin-bottom: get-vw(10px);
        }
        .stat{
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;

          > div{
            padding: get-vw(7px);
            flex-basis: 50%;
            span:first-child{
              display: block;
              color: $black-color;
              font-size: get-vw(12px);

            }
            span:last-child{
              display: block;
              color: $cta-blue-bg;
              font-size: get-vw(12px);
            }
          }
        }
        a{
          color : $main-color;
          font-size: get-vw(12px);
          text-decoration-line: underline;
        }

        .more{
          text-align: center;
        }
      }

      &.question_block{
        padding: get-vw(25px) get-vw(16px);

        .title{
          color : $main-color;

          margin-bottom : get-vw(9px);
          font-size: get-vw(10px);
          line-height: get-vw(15px);
          letter-spacing: get-vw(0.2px);
        }

        .date{
          color: $main-color;
          font-size: get-vw(8px);
          margin-bottom : get-vw(9px);

          span{
            color: $skills-color;
          }

        }

        .description{
          color: $main-color;
          font-size: get-vw(10px);
          line-height: get-vw(18px);


          margin-bottom: get-vw(10px);
        }

        .stat{
          display: flex;
          flex-direction: row;
          gap: get-vw(13px);

          > div{

            //like and answer count
            &:nth-of-type(1),
            &:nth-of-type(2)
            {
              background-repeat: no-repeat;
              background-position: left center;

              padding-left: get-vw(20px);

              color: $question-stat-color;


              font-size: get-vw(10px);

              line-height: get-vw(25px);
            }

            &:nth-of-type(1){
              background-image : url("../images/mobile/question_like.svg");
              background-size : get-vw(13px) auto;
            }

            &:nth-of-type(2){
              background-image : url("../images/mobile/question_answer_count.svg");
              background-size : get-vw(15px) auto;
            }

            //category
            &:nth-of-type(3){
              display: flex;
              gap: get-vw(10px);
              span:first-child{
                display: block;
                color: $question-stat-cat-color;
                background-color : $question-stat-cat-bg;
                font-size: get-vw(8px);
                line-height: get-vw(20px);
                padding: get-vw(2px) get-vw(10px);
                border-radius : get-vw(8px);


              }
              span:last-child{
                display: block;
                color: $question-stat-cat-more-color;
                background-color : $question-stat-cat-more-bg;
                font-size: get-vw(8px);


                width: get-vw(23px);
                height: get-vw(23px);
                border-radius: 100%;
                text-align: center;
                line-height: get-vw(23px);
              }
            }
          }

        }
        a{
          color : $main-color;
          font-size: get-vw(12px);
          text-decoration-line: underline;
        }

        .more{
          text-align: center;
        }
      }

      &.document_block{
        padding: get-vw(25px) get-vw(16px);

        .title{
          color : $main-color;

          margin-bottom : get-vw(9px);
          font-size: get-vw(10px);
          line-height: get-vw(15px);
          letter-spacing: get-vw(0.2px);
        }

        .date{
          color: $main-color;
          font-size: get-vw(8px);
          margin-bottom : get-vw(9px);

          span{
            color: $skills-color;
          }

        }

        .stat{
          display: flex;
          flex-direction: row;
          gap: get-vw(13px);

          > div{

            //like and answer count
            &:nth-of-type(1)
            {
              background-repeat: no-repeat;
              background-position: left center;

              padding-left: get-vw(20px);

              color: $question-stat-color;


              font-size: get-vw(10px);

              line-height: get-vw(25px);
            }

            &:nth-of-type(1){
              background-image : url("../images/mobile/question_like.svg");
              background-size : get-vw(13px) auto;
            }

            //category
            &:nth-of-type(2){
              display: flex;
              gap: get-vw(10px);
              span:not(.more){
                display: block;
                color: $question-stat-cat-color;
                background-color : $question-stat-cat-bg;
                font-size: get-vw(8px);
                line-height: get-vw(20px);
                padding: get-vw(2px) get-vw(10px);
                border-radius : get-vw(8px);


              }
              span.more{
                display: block;
                color: $question-stat-cat-more-color;
                background-color : $question-stat-cat-more-bg;
                font-size: get-vw(8px);


                width: get-vw(23px);
                height: get-vw(23px);
                border-radius: 100%;
                text-align: center;
                line-height: get-vw(23px);
              }
            }
          }

        }
        a{
          color : $main-color;
          font-size: get-vw(12px);
          text-decoration-line: underline;
        }

        .more{
          text-align: center;
        }
      }

    }

    /* block Mission */
    .mission_block_wrapper {
      @extend .generic_data_wrapper;

      .match_item{
        @extend .generic_data_block;

        display: flex;
        flex-direction: row;
        gap : get-vw(10px);

        padding: get-vw(10px);

        .title{
          display: flex;
          flex-direction: row;
          justify-content: flex-start;

          gap : get-vw(5px);

          &:before{
            content : "";
            display: inline-block;

            width: get-vw(15px);
            height: get-vw(15px);

            background-color : $main-color;
            border-radius : get-vw(2px);
          }
        }
      }
    }

    .step {
      font-size : 1rem;
      padding: get-vw(5px) get-vw(7px);
      border-radius: get-vw(5px);
    }

    .item_label {
      @extend .generic_data_block_label;
      background-size: get-vw(50px) auto;
    }

    .mission_block_item {
      @extend .generic_data_block;

      position: relative;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap : get-vw(5px);

      margin: 10px 0;
      padding: 30px;

      cursor: pointer;

      .mission_block_item_inner {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap : get-vw(5px);
      }

      .content{
        flex-grow: 1;

        .title{
          font-size : get-vw(16px);
        }
        .subtitle{
          font-size : get-vw(12px);
        }
        .description{

          span.reference{
            position: relative;
            display: inline-block;
            font-size : get-vw(14px);
            font-weight: 700;
            color : var(--profil-color4);
          }
          span.state{
            position: relative;
            color : $block-mission-secondary-color;
            font-size : get-vw(14px);

            padding-left: get-vw(9px);
            margin-left: get-vw(7px);

            &:before{
              content : "";
              display: block;

              position: absolute;

              left: 0;
              top: 50%;

              transform: translateY(-50%);

              width: get-vw(3px);
              height: get-vw(3px);

              border-radius : 100%;
              background-color : $block-mission-secondary-color;
            }
          }
        }
        .detail {
          margin: 15px 0;
          display: block;
          font-size : 16px;
          font-weight: 400;
          //color: ;
        }

        .progression{
          position: absolute;
          right: get-vw(13px);
          top: 30px;

          transform: translateY(-50%);

          color : var(--profil-color3);
          background-color : var(--profil-color2);

          line-height: get-vw(35px);
          padding: 0 get-vw(10px);
          border-radius : get-vw(6px);
        }
      }

      .date {
        top: get-vw(10px);
        right: get-vw(15px);

        font-size: 1rem;
      }
    }

    //mission item for recruteur
    .page_wrapper[data-profil="0"] {
      .mission_block_item .item_label {
        background-image: url("../images/mobile/mission_recruteur_1.png");
      }

      #match_details {
        //margin-top: 100px;
      }
    }

    .help_block_wrapper {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }

    /* block help */
    .help_block_item {
      @extend .generic_data_block;

      position: relative;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: get-vw(5px);

      margin: 10px;

      cursor: pointer;

      .help_block_item_inner {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        gap : get-vw(10px);
      }

      .item_label {
        @extend .generic_data_block_label;
        background-size: get-vw(21px) auto;
        background-image: url("../images/mobile/picto_other2.png");
      }

      .icon_circle {
        width: 50px;
        height: 50px;
      }

      .content {
        flex-grow: 1;
        margin-left: 0;

        .title {
          font-size: get-vw(16px);
        }

        .description {

          color: $block-mission-secondary-color;
          font-size: get-vw(14px);

          span.type {
            font-size: get-vw(14px);
          }

          span.reference {
            position: relative;
            display: inline-block;
            font-size : get-vw(14px);
            font-weight: 700;
            color : var(--profil-color4);
          }

          span.state {
            position: relative;
            color: $block-mission-secondary-color;
            font-size : get-vw(14px);

            padding-left: get-vw(9px);
            margin-left: get-vw(5px);

            &:before {
              content: "";
              display: block;

              position: absolute;

              left: 0;
              top: 50%;

              transform: translateY(-50%);

              width: get-vw(3px);
              height: get-vw(3px);

              border-radius: 100%;
              background-color: $block-mission-secondary-color;
            }

          }
        }
      }

      &.own {
        .item_label {
          background-image: url("../images/mobile/picto_other1.png");
        }
      }

      &.full {
        cursor: auto;
        padding: 30px;
        margin: 10px;

        .help_header {
          gap : get-vw(10px);

          .icon_circle {
            width: get-vw(40px);
            height: get-vw(40px);
          }

          .content {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-start;
          }

          .date{
            font-size : 1.08rem;
            position: relative;
            top: inherit;
            right: inherit;
            //top: get-vw(30px);
            //right: get-vw(15px);
          }
        }

        .description {
          padding: 15px 0;
        }

        .help_cta_wrapper {
          padding-top: get-vw(10px);
          flex-direction: row;
          align-items: center;
          justify-content: center;

          width: 100%;

          button {
            border-radius: 10px;
            line-height: get-vw(20px);
            height: get-vw(40px);
            font-size: get-vw(12px);
          }
        }

        &.urgent {
          margin-top: 10px;
        }
      }

      .date {
        top: get-vw(10px);
        right: get-vw(15px);

        font-size: 1rem;
      }
    }

    /* block switch */
    .switch_block_wrapper {
      @extend .generic_data_wrapper;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }

    .urgent_label {
      top: -10px;
      right: 15px;
      padding: 10px 20px;

      &:after {
        border-radius: 10px;
      }
    }

    .switch_block_item {
        @extend .generic_data_block;

        position: relative;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: get-vw(5px);

        margin: 10px;

        cursor: pointer;

        .switch_block_item_inner {
          gap : get-vw(10px);
        }

        .item_label {
          @extend .generic_data_block_label;
          background-size: get-vw(21px) auto;
          background-image: url("../images/mobile/picto_other2.png");
        }

        .icon_circle {
          width: 50px;
          height: 50px;
        }

        .content {
          flex-grow: 1;
          margin-left: 0;

          .title {
            font-size: get-vw(16px);
          }

          .description {

            color: $block-mission-secondary-color;
            font-size: get-vw(14px);

            span.type {
              font-size: get-vw(14px);
            }

            span.reference {
              position: relative;
              display: inline-block;
              font-size : get-vw(14px);
              font-weight: 700;
              color : var(--profil-color4);
            }

            span.state {
              position: relative;
              color: $block-mission-secondary-color;
              font-size : get-vw(14px);

              padding-left: get-vw(9px);
              margin-left: get-vw(5px);

              &:before {
                content: "";
                display: block;

                position: absolute;

                left: 0;
                top: 50%;

                transform: translateY(-50%);

                width: get-vw(3px);
                height: get-vw(3px);

                border-radius: 100%;
                background-color: $block-mission-secondary-color;
              }
            }
          }
        }

        &.own {
          .item_label {
            background-image: url("../images/mobile/picto_other1.png");
          }
        }

      &.full {
        cursor: auto;
        padding: 30px;
        margin: 10px;

        .switch_header {
          gap : get-vw(10px);

          .icon_circle {
            width: get-vw(40px);
            height: get-vw(40px);
          }

          .content {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-start;
          }

          .date{
            font-size : 1.08rem;
            position: relative;
            top: inherit;
            right: inherit;
            color : $block-help-date-color;
            //top: get-vw(30px);
            //right: get-vw(15px);
          }

          //.date{
          //  font-size : 1.08rem;
          //  position: absolute;
          //  top: get-vw(30px);
          //  right: get-vw(15px);
          //}
        }

        .description {
          padding: 15px 0;
        }

        .switch_cta_wrapper {
          padding-top: 20px;
          flex-direction: row;
          align-items: center;
          justify-content: center;

          width: 100%;

          button {
            border-radius: 10px;
            line-height: get-vw(20px);
            height: get-vw(40px);
            font-size: get-vw(12px);
          }

        }

        &.urgent {
          margin-top: 10px;
        }
      }

      .date {
        top: get-vw(10px);
        right: get-vw(15px);

        font-size: 1rem;
      }
    }

    .question_block_wrapper {
      .bloc_title_info {
        border-radius: get-vw(10px);
        padding: get-vw(20px) get-vw(15px);

        color: $white-color;
        font-size: 1.16rem;
      }
    }

    .question_block_item {

      &:hover {
        cursor: pointer;
      }

      border-radius: get-vw(10px);
      border: get-vw(1px) solid $dashboard-block-border;

      padding: get-vw(20px) get-vw(25px);

      margin: get-vw(10px);

      .question_header_title {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: get-vw(5px);
        margin-bottom: get-vw(10px);

        .picture {
          width: get-vw(34px);
          height: get-vw(34px);
        }
      }

      .title{
        margin-bottom : get-vw(2px);
        font-size: get-vw(16px);
        line-height: get-vw(20px);
        letter-spacing: get-vw(0.2px);
      }

      .date{
        font-size: get-vw(8px);
        margin-bottom : get-vw(9px);

        span{
        }

      }

      .description{
        font-size: get-vw(14px);
        line-height: get-vw(18px);

        margin-bottom: get-vw(10px);
      }

      .stat {
        gap: get-vw(13px);

        > div{

          &:hover {
            cursor: pointer;
          }

          //like and answer count
          &:nth-of-type(1),
          &:nth-of-type(2)
          {
            background-repeat: no-repeat;
            background-position: left center;

            padding-left: get-vw(20px);

            color: $question-stat-color;
            font-size: get-vw(12px);

            line-height: get-vw(25px);
          }

          &:nth-of-type(1){
            background-size : get-vw(13px) auto;
          }

          &:nth-of-type(2){
            background-size : get-vw(15px) auto;
          }

          //category
          &:nth-of-type(3){
            display: flex;
            gap: get-vw(10px);
            span:first-child{
              display: block;
              color: $question-stat-cat-color;
              background-color : $question-stat-cat-bg;
              font-size: get-vw(8px);
              line-height: get-vw(20px);
              padding: get-vw(2px) get-vw(10px);
              border-radius : get-vw(8px);
            }
            span:last-child{
              display: block;
              color: $question-stat-cat-more-color;
              background-color : $question-stat-cat-more-bg;
              font-size: get-vw(8px);

              width: get-vw(23px);
              height: get-vw(23px);
              border-radius: 100%;
              text-align: center;
              line-height: get-vw(23px);
            }
          }
        }
      }

      a{
        color : $main-color;
        font-size: get-vw(12px);
        text-decoration-line: underline;
      }

      .more{
        text-align: center;
      }
    }

    .single_question_header {


      .picture {
        background-image : none !important;
        background-size : 100% auto;
        width: get-vw(40px);
        height: get-vw(40px);

        border-radius: get-vw(5px);
      }

      .single_question_header_title {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: get-vw(5px);
        margin-bottom: get-vw(10px);

        .picture {
          width: get-vw(24px);
          height: get-vw(24px);
        }

        h2 {
          margin-bottom: 0;
          margin-top: 0 !important;
        }
      }

      .single_item_detail_block_description {
        margin-top: get-vw(15px);
      }

      .stat {
        display: flex;
        flex-direction: row;
        gap: get-vw(10px);

        > div {

          //like and answer count
          &:nth-of-type(1),
          &:nth-of-type(2) {
            background-repeat: no-repeat;
            background-position: left center;

            padding-left: get-vw(24px);

            color: $question-stat-color;
            font-size: get-vw(14px);

            line-height: get-vw(25px);
          }

          &:nth-of-type(1) {
            background-image: url("../images/mobile/question_like.svg");
            background-size: get-vw(16px) auto;
          }

          &:nth-of-type(2) {
            background-image: url("../images/mobile/question_answer_count.svg");
            background-size: get-vw(18px) auto;
          }
        }
      }
    }

    .question_answer_item {
      padding: get-vw(10px);
      border-bottom: 1px solid $main-color;

      .question_answer_header {
        display: flex;
        flex-direction: row;
        gap: get-vw(5px);
        margin-bottom: get-vw(10px);

        .picture {
          width: get-vw(30px);
          height: get-vw(30px);
        }

        .question_answer_header_title {
          display: flex;
          flex-direction: row;
          align-items: center;
          //justify-content: space-between;
          gap: get-vw(10px);
        }

        .title {
          font-size: get-vw(16px);
        }
      }
      .description {
        font-size: get-vw(14px);
      }
    }


    /* block single match */
    .single_match_block{
      //@extend .generic_data_wrapper;
      padding: 20px 0;

      &+.single_match_block{
      }
    }

    #content_missions_wrapper {
      .single_item_match_listing_wrapper {
        .side-left {
          .cta.return {
            display: none;
          }
        }
      }
    }

    #content_matchs_wrapper {
      #single_match_wrapper {
        background: $white-color;
        padding: 30px;
        position: relative;

        > .cta_wrapper {
          display: block !important;
        }

        #match_header {
          top: 30px;
          right: 30px;

          .cta.return {
            display: none;
          }
        }

        #match_details {
          margin-top: 50px;
        }

        #cta_wrapper {
          top: 20px;
        }
      }
    }

    .single_match_recruteur {
      position: relative;
      padding: 20px;

      &#single_match_wrapper {

        .single_match_block {
          flex-direction: column;

          .profilDataBlock {
            .title {
              text-align: left;
            }
          }
        }

        #cta_wrapper {
          display: flex;
          flex-direction: row;
          position: absolute;
          top: 0;
          bottom: inherit;
          left: 50%;
          transform: translateX(-50%);
          padding: 0 20px;
          gap: 10px;
          width: 80%;
          justify-content: center;

          .cta {
            max-width: 260px;
          }
        }
      }
    }

    /* slinding panel from bottom */
    #sliding_panel {
      border-radius: get-vw(32px) get-vw(32px) 0 0;

      &.show {
        height: calc(100% - get-vw(80px));
      }
    }

    #modal_panel {
      border-radius: 20px;
    }

    #sliding_panel_body{
      padding: 40px 45px;
    }

    #sliding_panel_close{
      position: absolute;
      top: 17px;
      right: 19px;

      width: 30px;
      height: 30px;
    }

    /* BLOCK PROFILE */
    .profil_block{
      margin-bottom: get-vw(40px);
    }

    /* BLOC competence */
    .profilDataBlock{
      position: relative;
      border-radius: get-vw(15px);
      border: 1px solid $form-style2-border;
      background: #FFF;
      box-shadow: 0 get-vw(3px) get-vw(4px) 0 rgba(227, 227, 227, 0.29);

      margin-bottom : get-vw(7px);
      padding: get-vw(15px);

      .title{
        color: $main-color;
        font-size: 14px;
        letter-spacing: get-vw(0.24px);

        padding-right: get-vw(50px);
        line-height: normal;
      }

      .experience,
      .international{
        color : $skills-color;

        font-size: 14px;
        line-height: 16px;
        letter-spacing: get-vw(0.2px);

        padding : get-vw(3px) 0;
      }

      .description{
        color: $main-color;
        font-size: 14px;
        line-height: 18px;
        margin-bottom: 10px;
      }

      .subarea {
        padding: get-vw(5px) 0;

        display: flex;
        flex-wrap: wrap;
        flex-direction: row;

        gap: get-vw(5px);

        span {
          background: $label-bg;
          color: $label-color;

          text-align: center;

          padding: 0 get-vw(10px);
          line-height: 20px;
          height: 20px;
          font-size: 14px;

          border-radius: get-vw(6px);
        }
      }

      .cta_wrapper{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;

        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;

        gap: get-vw(5px);

        padding: get-vw(5px) get-vw(10px);

        .cta{
          font-size : 0;

          &.edit{
            background-image : url("../images/mobile/edit.svg");
            background-position: center;
            background-size : 100% auto;
            background-repeat: no-repeat;

            width: get-vw(20px);
            height: get-vw(20px);
          }

          &.delete{
            background-image : url("../images/mobile/delete_cross.svg");
            background-position: center;
            background-size : 100% auto;
            background-repeat: no-repeat;

            width: get-vw(20px);
            height: get-vw(20px);
          }
        }

      }
    }

    /* BLOC notification */
    .notification_block_wrapper {
      @extend .generic_data_wrapper;
      .notification_item{
        @extend .generic_data_block;

        display: flex;
        flex-direction: row;
        gap : get-vw(10px);

        padding: 20px;
        margin-bottom: 10px;

        .picture{
          @extend .generic_data_block_picture;
        }

        .content {
          .title {
            font-size: 14px;
          }

          .description {
            font-size: 12px;
            margin-top: 5px;
            margin-bottom: 10px;
          }

          .date {
            font-size: 10px;
          }
        }

        .actions {
          .delete {
            width: 20px;
            height: 20px;
          }
        }
      }
    }

    /* Availability block */
    .availability_checkbox{

      background-image : url("../images/mobile/calendar.svg");
      background-size : get-vw(30px) auto;
      background-position: get-vw(18px) center;
      background-repeat: no-repeat;

      background-color : $availability-wrapper-bg;

      padding: get-vw(12px) get-vw(15px) get-vw(16px) get-vw(67px);

      margin: get-vw(18px) auto;

      .title{
        color: $main-color;

        font-size: get-vw(10px);
        line-height: get-vw(20px);

        margin-bottom : get-vw(10px);
      }

      .checkbox_availability_wrapper{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        gap: get-vw(15px);


        input{
          opacity: 0;
          position: absolute;
          z-index: -999;
        }

        input + label{
          display: inline-block;

          padding : get-vw(6px) get-vw(7px);
          border-radius : get-vw(5px);

          color: $cta-disabled-blue-color;
          border: get-vw(1px) solid $cta-disabled-blue-border;

          text-align: center;


          font-size: get-vw(10px);

          background-color : $cta-disabled-blue-bg;
          cursor: pointer;
        }

        input:checked + label{
          color: $cta-blue-color;
          border: get-vw(1px) solid $cta-blue-bg;
          background-color : $cta-blue-bg;
        }
      }
    }

    /* filter button bar*/
    .button_bar{
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;

      gap: get-vw(15px);

      padding: get-vw(20px) 0;

      .button_item{
        input{
          position: absolute;
          opacity: 0;
          z-index: -999;
        }

        input + label{
          display: block;


          text-align: center;
          font-size: get-vw(10px);

          text-decoration: none;

          background-color : $buttonbar-inactive-bg;
          color : $buttonbar-inactive-color;
          padding: get-vw(5px) get-vw(10px);

          border-radius: get-vw(15px);
          cursor: pointer;
        }

        input:checked + label{
          background-color : $buttonbar-active-bg;
          color : $buttonbar-active-color;
        }
      }
    }

    //new filter bar
    .filterbar_wrapper{
      position: relative;
      z-index: 0;

      margin-bottom: get-vw(20px);

      &:after{
        content: "";
        display: block;

        position: absolute;
        left: 0;
        bottom: 0;
        z-index: -1;

        width: 100%;
        height: get-vw(4px);
        background-color : $buttonbar-inactive-color;
        opacity: 0.2;
      }

      //inactive item
      ul{
        li{
          color : $buttonbar-inactive-color !important;
          padding: get-vw(12px) get-vw(15px) !important;
          font-size : 1.16rem;
        }
        &+div{
          background: $buttonbar-active-bg !important;
          height: get-vw(4px) !important;
          z-index: 9;
        }
      }

      //ActiveItem
      @for $i from 0 through 99 {
        &[index="#{$i}"]ul li:nth-of-type(#{$i+1}){
          color : $buttonbar-active-color  !important;
        }
      }

    }

    /* Intro bloc with title and subtitle */
    .bloc_title_info{
      margin-bottom : get-vw(20px);

      p {
        color: $generic_bloc_secondary_color;
        font-size: 1.2rem;

        margin-bottom : 0;
      }
    }

    .bloc_subtitle_info {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .subtitle {
        font-size: get-vw(18px);
      }
    }


    /* barre de recherche */
    .searchbar_filter{
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      margin-bottom : get-vw(10px);

      font-size: get-vw(10px);
      letter-spacing: get-vw(0.2px);

      input.searchbar{
        background-image : url("../images/mobile/search_glass.svg");
        background-size: get-vw(20px) auto;
        background-repeat: no-repeat;
        background-position: left get-vw(12px) center;

        border: 0;
        border-radius: get-vw(6px);
        box-shadow: 0 get-vw(3px) get-vw(4px) 0 rgba(227, 227, 227, 0.29);

        height: get-vw(50px);
        width: 100%;



        padding-left: get-vw(40px);
        padding-right: get-vw(10px);

        margin-bottom : get-vw(13px);

      }
      select{
        border-radius: get-vw(6px);
        background-color : $white-color;
        border: 0;
        box-shadow: 0 get-vw(3px) get-vw(4px) 0 rgba(227, 227, 227, 0.29);

        max-width: get-vw(150px);

        -webkit-appearance: none;
        -moz-appearance: none;

        background-image : url("../images/mobile/select_dropdown.svg");
        background-size: get-vw(8px) auto;
        background-repeat: no-repeat;
        background-position: right get-vw(10px) center;
        padding: get-vw(5px) get-vw(30px) get-vw(5px) get-vw(12px);
      }
    }

    /* single content for mission, help, switch*/
    .single_item_wrapper {
      display: flex;
      flex-direction: row;


      .single_item_header{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap : get-vw(10px);

        background: $white-color;
        padding: 30px;
        border-radius: 20px;

        color : $generic_bloc_main_color;

        .cta_wrapper{
          flex-basis: 100%;
        }

        .picture{
          background-image : url("../images/mobile/mission_avatar.png");
          background-size : 100% auto;
          width: get-vw(50px) !important;
          height: get-vw(50px) !important;

          border-radius: get-vw(5px);
        }

        .content{
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          flex-grow: 1;

          .title{
            font-size: get-vw(14px);

          }
          .description{
            color : $generic_bloc_secondary_color;
          }
          .other{
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            .detail{
              text-decoration: underline;
              cursor: pointer;
              font-weight: bold;
            }
          }
        }

        .urgent_label {
          top: get-vw(30px);
          right: get-vw(30px);
        }

        .single_item_header_actions {
          top: get-vw(30px);
          right: get-vw(20px);

          gap: get-vw(10px);
        }
      }
      .other_single_item_header{
        position: relative;

        display: flex;
        flex-direction: column;
        gap : get-vw(10px);

        color : $generic_bloc_main_color;

        padding-top : get-vw(140px);

        .picture{
          position: absolute;
          left: $content-x-padding * -1;
          top: 0;

          background-image : url("../images/mobile/singleItemHeader.png");
          background-size : 100% auto;
          height: get-vw(120px);
          width: 100vw;
        }

        .content{
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          flex-grow: 1;

          .title{
            font-size: get-vw(14px);

            margin-bottom: get-vw(15px);
          }

          .other_single_item_data{
            display: flex;
            flex-direction: row;
            gap : get-vw(10px);

            margin-bottom: get-vw(5px);

            label{

            }
            div{
              flex-grow: 1;
              text-align: right;
            }
          }

          .description{
            flex-wrap: wrap;
            gap: 0;

            label{ width : 100%; }
            div{text-align: left;}
          }

        }
      }

      .single_item_details{

        h2 {
          margin-top: 20px;
        }
        .single_mission_details_inner{
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          justify-content: space-between;

          margin-top: 20px;
        }

        .single_item_detail_block{
          margin-bottom: 20px;

          h3{
            margin-bottom : get-vw(10px);
          }
          > div {
            line-height: get-vw(10px);
            margin: 0 5px 20px 0;
          }
        }

        .single_item_detail_block_description {
          display: block;
          font-size: 16px;
          font-weight: 400;
        }
      }

      .single_item_cta_wrapper{
        padding-top: get-vw(30px);
        position: inherit;
        bottom: inherit;
        left: inherit;
        padding: 20px 0;
      }

    }

    .single_item_wrapper_border {

      .single_item_header {
        padding: get-vw(20px);
        border-radius: get-vw(15px);
        margin-bottom: get-vw(15px);
        position: relative;

        flex-direction: column;
      }
    }

    /* match listing for single content mission, help, switch*/
    .single_item_match_listing_wrapper {

      .side-left {
        flex: 3;
      }
      .side-right {
        flex: 1;
      }
      .side-right-last {
        flex: 2;
      }

      .generic_data_wrapper {
        display: flex;
        flex-direction: row;
        background: $white-color;
        border-radius: 20px;
        margin-top: 10px;
      }

      .title {
        text-align: left;
      }

      .match_item{
        @extend .generic_data_block;

        display: flex;
        flex-direction: row;
        gap : get-vw(10px);

        padding: get-vw(10px);

        .title{
          display: flex;
          flex-direction: row;
          justify-content: flex-start;

          gap : get-vw(5px);

          //&:before{
          //  content : "";
          //  display: inline-block;
          //
          //  width: get-vw(15px);
          //  height: get-vw(15px);
          //
          //  background-color : $main-color;
          //  border-radius : get-vw(2px);
          //}
        }

        &.liked{
          background-image : url("../images/mobile/match_like_active.svg");
          background-repeat: no-repeat;
          background-position: top get-vw(5px) right get-vw(5px);
          background-size: get-vw(24px) auto;
        }
      }
    }

    /* Messagerie */
    .conversation_wrapper {
      display: flex;
      flex-direction: row;
      padding: 30px;

      background: $white-color;

      box-shadow: 0 get-vw(3px) get-vw(4px) 0 rgba(227, 227, 227, 0.29);
      border-radius: get-vw(10px);

      max-height: 80vh;

      .side-left {
        padding: 10px;

        #page_conversation_single {
          max-height: 100%;

          > .cta_wrapper {
            display: none;
          }
        }
      }

      .side-right {
        max-height: 100%;
        overflow: scroll;
        margin: 0;
      }
    }

    .conversation_block_item{
      gap : get-vw(5px);

      margin-bottom: 0;
      padding: 15px;
      border-bottom: 1px solid $dashboard-block-border;
      border-radius: 0;
      box-shadow: none;

      cursor: pointer;

      .picture {
        width: 50px;
        height: 50px;
      }

      .content {
        flex: 1;
        margin-left: 10px;

        .title {
          font-size: get-vw(16px);
        }

        .subtitle {
          font-size: get-vw(12px);
        }

        div.last_message{
        }

      }

      .extra {
        .time {
          font-size: get-vw(12px);
        }

        .author-info {
          font-size: get-vw(14px);
        }

      }


      .conversation-link-see {
        display: flex;
      }
    }

    .side-left {
      display: flex;
      flex-direction: column;
      flex: 2;
      margin: 0 30px;
    }

    .side-right {
      display: flex;
      flex-direction: column;
      flex: 1;
      margin: 0 30px;
    }

    .border-side-right {
      border-right: 1px solid $dashboard-block-border;
    }

    .border-side-left {
      border-left: 1px solid $dashboard-block-border;
    }

    /*Chat*/
    #page_conversation_single{
      .cs-main-container{
        margin-top: 10px;
        margin-left: 0;
        margin-right: 0;
        border: 0;
        background-color : $white-color;


        .cs-chat-container{
          max-height: calc(100vh - get-vw(150px)); // full height minus header height
        }


        .cs-conversation-header{
          background-color : $white-color;

          .cs-conversation-header__back {
            display: none;
          }

          .cs-conversation-header__content > div{
            background-color : transparent;
          }

          .cs-conversation-header__user-name {
            font-size:  get-vw(18px);
          }

          .cs-conversation-header__info {
            font-size:  get-vw(12px);
          }

          .cs-conversation-header__actions {
            .cs-button {
              font-size:  get-vw(22px);
              width: get-vw(44px);
              height: get-vw(44px);
              padding-top: get-vw(5px);
            }
          }
        }

        .cs-message-list{
          padding-top : get-vw(20px);
          background-color : $white-color;
          overflow: auto;
        }
        .cs-message-separator{
          background-color : transparent;
          font-size : 0.9rem;


          &:before,&:after{
            opacity: 0;
          }
        }
        .cs-message{

          .cs-message__avatar{
            width: get-vw(40px);
            height: get-vw(40px);

            line-height: get-vw(40px);
            text-align: center;

            background-color : $main-color;
            border-radius : 100%;

            font-size : 1rem;
            text-transform: uppercase;
            font-weight: bold;
            color : $white-color;

            > div{
              width: 100%;
              height: 100%;

              min-height: auto;
              min-width: auto;
            }
          }

          .cs-message__content-wrapper{
            border-radius : get-vw(5px);
            .cs-message__content{
              background-color : transparent;
              font-size : 1.1rem;
              padding: get-vw(5px) get-vw(10px);
            }
            .cs-message__footer{
              font-size : 0.75rem;
              padding: 0 get-vw(10px) get-vw(5px) get-vw(10px);

              .cs-message__sent-time{
                padding: 0;
              }
            }
          }

          //incoming message
          &.cs-message--incoming .cs-message__content-wrapper {
            background-color : $chat-message-incoming-bg;
            border-top-left-radius: 0;


            .cs-message__content{
              color : $chat-message-incoming-color;
            }
            .cs-message__footer .cs-message__sent-time{
              color : $chat-message-incoming-color;
              margin-left: 0;
              order : 1;
            }
          }

          //outgoing message
          &.cs-message--outgoing .cs-message__content-wrapper {
            background-color : $chat-message-outgoing-bg;
            border-top-right-radius: 0;

            .cs-message__content{
              color : $chat-message-outgoing-color;
            }
            .cs-message__footer .cs-message__sent-time{
              color : $chat-message-outgoing-color;
            }
          }
        }

        .cs-message-input{
          padding: get-vw(15px);
          border: 0;
          background-color : $white-color;
          .cs-message-input__tools{
            display: none;
          }
          .cs-message-input__content-editor-wrapper{
            background-color : $chat-input-bg-color;
            border: 1px solid $dashboard-block-border;

            .cs-message-input__content-editor{
              background-color : $chat-input-bg-color;
              line-height: get-vw(40px);
              font-size : 1.3rem;
            }
          }
        }
      }
    }

    .video_wrapper {
      padding: get-vw(20px);

      .video_inner {
        max-width: get-vw(1200px);
        position: relative;

        .localVideo {
        }

        video {
          min-width: 100%;
          min-height: 100%;
          object-fit: contain;
        }

        .localVideoOff {
          background-color: $main-color;
          background-position: center;
          background-size: contain;
          background-repeat: no-repeat;
          background-image: url("../images/mobile/user-circle-owner.png");

          border: 1px solid $main-color;

          video {
            display: none;
          }
        }

        .remoteVideo {
        }

        .remoteVideoOff {
          background-color: $main-color;
          background-image: url("../images/mobile/user-circle-owner.png");
          background-position: center;
          background-size: contain;
          background-repeat: no-repeat;

          border: 1px solid $main-color;

          video {
            opacity: 0;
          }
        }


        .full {
          width: 100%;
          height: 100%;
          max-height: 800px;
          overflow: hidden;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .corner {
          top: get-vw(20px);
          right: get-vw(20px);

          width: get-vw(250px);
          height: get-vw(150px);
        }

        video {
          width: 100%;
          height: 100%;
        }

        .video_actions {
          //bottom: get-vw(60px);
          padding: get-vw(20px);

          width: 100%;

          background: rgba(0, 0, 0, 0.5);

          .endCall {
            width: get-vw(50px);
            height: get-vw(50px);

            cursor: pointer;
          }

          .mute {
            width: get-vw(50px);
            height: get-vw(50px);
          }
          .camera {
            width: get-vw(50px);
            height: get-vw(50px);
          }
        }
      }
    }

    .subscription_wrapper {
      max-width: 600px;
      margin: auto;
    }

    .subscription_block_item {
      padding: 18px;
      border-radius : 8px;
      border : 1px solid $subscription_block_border;

      margin-bottom : 20px;
      cursor: pointer;

      position: relative;

      .input{
        padding-right: get-vw(20px);

        input{
          position: absolute;
          z-index: -9999;
        }

        input+label{
          display: inline-block;
          width: get-vw(24px);
          height: get-vw(24px);

          border-radius: 100%;
          border: 1px solid $subscription_block_border;
        }

        input:checked+label{
          background-color : $subscription_block_active_border;
          border-color : $subscription_block_active_border;

          background-image : url("../images/mobile/subscription_check.svg");
          background-size : get-vw(10px) auto;
          background-repeat: no-repeat;
          background-position: center;
        }
      }
      .content{
        flex-grow: 1;

        .title{
          font-size : 1.16rem;
          color : $subscription_block_title;
        }

        .description{
          color : $subscription_block_description;
        }

        .details {
          margin-top: get-vw(10px);
        }

        .price{
          text-align: right;
          font-size : 1.33rem;
          color : $subscription_block_title;
          padding-top: get-vw(12px);
          span{
            font-size : 1.66rem;
          }

          position: absolute;
          top: 0px;
          right: 10px;
        }
      }

      &.active{
        border-color : $subscription_block_active_border;
      }
    }

    .subscription_state {
      justify-content: flex-start;

      .subscription_state_text {
        font-size: get-vw(16px);
        margin-right: 30px;
      }
    }

    .subscription_info {
      margin: get-vw(20px) auto;
      padding: get-vw(30px);
      border-radius: 20px;
      background: $white-color;

      .subscription_info_text {
        font-size: get-vw(20px);
        color: $main-color;

        span {
          font-weight: 600;
          color: $profil-candidat-color;
        }

        &.small {
          font-size: get-vw(16px);
        }
      }
    }

    .subscription_action {
      .cta {
        max-width: 300px;
      }
    }

    .subscription_checkout_form {
      margin: 20px auto;
    }

    .subscription_extra_wrapper {
      margin-bottom: get-vw(15px);

      .subscription_extra {
        margin-top: get-vw(5px);
        padding: get-vw(8px);

        .subscription_extra_label {
          font-size: get-vw(12px);
        }

        .subscription_extra_value {
          font-size: get-vw(14px);
          color: $main-color;
        }
      }
    }
  }

  header{
    position: relative;
    height: get-vw(85px);
    padding: 0 $content-x-padding;

    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;

    gap : get-vw(10px);

    .logo {
      position: relative;
      background-image: inherit;
      background-size : inherit;
      background-repeat: no-repeat;

      width: inherit;
      height: inherit;

      display: flex;
      flex: 1;
      align-items: center;

      img {
        width: 110px;
        height: 40px;
        min-width: 110px;
      }

      left: inherit;
      transform: inherit;


      margin: auto;
    }

    .header-login-right {
      display: block;

      a {
        color: $cta-blue-bg;
        text-decoration: none;
      }
    }

    .header-inline-menu {
      display: flex;
      border-radius: 25px;
      padding: 5px;
      background: $white-color;
      border: 1px solid $grey-color;

      li {
        padding: 5px 30px;
        border-right: 1px solid $grey-color;

        &:last-child {
          border-right: 0;
        }

        a {
          text-decoration: none;

          &:hover {
            color: $main-color;
          }
        }
      }
    }

    #burger_menu{
      //background-image : url("../images/mobile/header_burger.svg");
      //background-size : 100% auto;
      //width: get-vw(12px);
      //height: get-vw(10px);
      //
      //cursor: pointer;
      display: none;
    }
    #notification{
      background-image : url("../images/mobile/header_notification.svg");
      background-size : 100% auto;
      width: get-vw(18px);
      height: get-vw(20px);

      cursor: pointer;
      margin-left: auto;

      &.notification-new:after {
        top: -3px;
        right: -2px;

        width: 10px;
        height: 10px;
      }

    }

    #logout{
      width: 24px;
      height: 24px;
    }

    #profile{
      overflow: hidden;
      width: get-vw(32px);
      height: get-vw(32px);

      border-radius : get-vw(8px);
      background-color : $form-upload-profil;
      margin-right: get-vw(7px);

      img{
        object-fit: cover;
        width: 100%;
        height: 100%;
      }

      &:hover {
        cursor: pointer;
      }
    }

    #mobile_menu_wrapper{
      position: absolute;
      left: 0;
      top: 0;
      z-index: 999;

      width: 0;
      height: 100vh;

      background-color : $white-color;

      transition: 0.5s;

      padding-top: get-vw(85px);
      padding-bottom: get-vw(150px);

      overflow: hidden;

      #mobile_menu_close{
        position: absolute;
        display: block;

        background-color : transparent;
        border: 0;
        outline: 0;

        background-image : url("../images/mobile/header_burger_close.svg");
        background-size : 100% auto;

        width: get-vw(20px);
        height: get-vw(20px);

        top: get-vw(32px);
        left: get-vw(25px);
      }
      nav{
        overflow: hidden;

        a{
          display: block;
          color: $main-color;
          font-size: get-vw(14px);
          text-decoration: none;

          padding: get-vw(10px) 0;

          border-bottom: get-vw(1px) solid $main-color;

          word-break: keep-all;
          white-space: nowrap;
        }
      }

      &.open{
        width: 80%;
        padding-left: get-vw(28px);
        padding-right: get-vw(20px);
      }
    }
  }


  /* PAGES */
  #page_404_wrapper{
    .content_wrapper{
      @extend .content_wrapper_vertically_centered;
    }
  }

  #page_connexion_wrapper,
  #page_forgot-password_wrapper,
  #page_reset-password_wrapper,
  #page_inscription_wrapper,
  #page_inscription_sso_wrapper {
    background-color : $white-color;

    .content_wrapper{
      @extend .content_wrapper_vertically_centered;
    }

    header {

      .logo {
        width: get-vw(172px);
        height: get-vw(60px);
      }
    }
  }
  #page_profile_wrapper {
    background-color : $background-color;
  }

  .user_profile_type {
    display: inline-block;
    line-height: get-vw(35px);
    font-size : 1.16rem !important;

    padding: 0 get-vw(10px);
    border-radius: get-vw(6px);

    background-color : var(--profil-color2);
    color : var(--profil-color) !important;
  }

  #page_dashboard_wrapper{

    .dashboard_page_wrapper {
      display: flex;
      flex-direction: row;
    }
    .dashboard-left {
      flex: 2;

    }

    .dashboard-right {
      flex: 1;

      .user_picture {
        text-align: center;

        .picture {
          width: 150px;
          height: 150px;
          margin: 0 auto 20px auto;
        }

        .name {
          color: $main-color;
          font-size: 18px;
        }

        .cta {
          margin-top: 20px;
          max-width: 150px;
          line-height: 37px;
          text-decoration: none;
        }
      }
    }

    .dashboard-stats-wrapper {
      display: flex;
      flex-direction: column;
      //align-items: flex-start;

      .match_block {
        padding: 0;
        margin-bottom: 0;
      }
    }

    .dashboard-stats-numbers {
        flex: 1
    }

    #dashboard_stat_block{
      //display: grid;
      //grid-template-columns: repeat(2, 1fr);
      justify-content: space-between;

      margin-bottom : get-vw(15px);

      .dashboard_item_stat {
        text-align: center;
        flex-basis: 25%;
        color : $main-color;
        font-size: 16px;
        margin-bottom: 20px;

        &:hover {
          cursor: pointer;
        }

        > div{
          background-size : 100% auto;
          background-repeat: no-repeat;

          width: get-vw(92px);
          height: get-vw(92px);
          line-height: get-vw(92px);

          font-size : get-vw(40px);

          margin : 0 auto get-vw(5px) auto;

        }
      }
    }

    .match_block .picture{
      width: 50px;
      height: 50px;
    }



    //profil candidat
    &[data-profil="1"]{
      #dashboard_stat_block .dashboard_item_stat{
        &:nth-of-type(1) > div {background-image :url("../images/mobile/stat_gold1.png");}
        &:nth-of-type(2) > div {background-image :url("../images/mobile/stat_gold2.png");}
        &:nth-of-type(3) > div {background-image :url("../images/mobile/stat_gold3.png");}
      }
    }

    //profil recruteur
    &[data-profil="0"]{
      #dashboard_stat_block .dashboard_item_stat{
        &:nth-of-type(1) > div {background-image :url("../images/mobile/stat_blue1.png");}
        &:nth-of-type(2) > div {background-image :url("../images/mobile/stat_blue2.png");}
        &:nth-of-type(3) > div {background-image :url("../images/mobile/stat_blue3.png");}
      }
    }

    .mission_block_item {
      border-top: 1px solid $dashboard-block-border;
      border-radius: 0;
      padding: 15px;

      align-items: flex-start;

      .detail {
        display: none;
      }

      .content {
        margin-right: 50px;
        margin-top: 10px;
      }
    }
  }

  .profile-header {
    //height: 300px;
    background: $white-color;
    border-radius: 10px;
    box-shadow: get-vw(-1px) get-vw(1px) get-vw(1px) $grey-color;
    overflow: hidden;
    position: relative;

    .profile-header-top {
      height: 78px;
      background: var(--profil-color4);
    }

    .profile-header-picture {
      width: 100px;
      height: 100px;
      border-radius: 50%;

      overflow: hidden;
      background: $grey-color;

      position: absolute;
      top : 50px;
      left: 50px;
    }

    .profile-header-content {
      display: flex;
      flex-direction: row;
      margin-left: 150px;
      padding: 30px;

      justify-content: space-between;
      align-items: center;

      .profile-header-name {
        font-size: 18px;
        color: $main-color;

        .user_profile_type {
          margin-left: 20px;
        }
      }

      .cta {
        max-width: 500px;
        line-height: 37px;
        text-decoration: none;
        color: $white-color;
        background: var(--profil-color-reverse);
      }

      .profile-header-skills {
        display: flex;
        gap: 20px;
        margin-top: 10px;

        ul {
          display: flex;
          flex-direction: row;
          list-style-type: circle;

          li {
            margin-right: 20px;
          }
        }
      }

      .profile-header-info {
        display: flex;
        flex-direction: row;
        gap: 30px;
        margin-top: 20px;

        font-weight: 300;
        font-size: 15px;
        color: $main-color;
      }
    }

    .profile-header-bottom {
      //display: flex;
      position: relative;
      //flex-direction: row;
      //justify-content: space-between;
      //padding: 30px;

      .profile-header-logout {
        position: absolute;
        right: 30px;
        bottom: 20px;

        font-size: 1.16rem;
        text-decoration: none;
        color: $main-color;
      }

      .profile-header-tabs {
        //display: flex;
        //flex-direction: row;
        //gap: 10px;
        border-top: 1px solid $match_block_border_color;

        .filterbar_wrapper {
          margin-bottom: 0;
        }

        .profile-header-tab {
          font-size: 18px;
          color: $main-color;
        }
      }
    }
  }

  .profile-container {
    padding: 30px;
    background: $white-color;
  }

  #sponsorship_wrapper {
    //display: flex;
    //flex-direction: row;

    .subtitle {
      font-size: 18px;
      color: $main-color;
    }

    .sponsorship_box {
      display: flex;
      flex-direction: row;

      .side-left {
        flex: 1
      }
      .side-right {
        flex: 2
      }
    }

    .sponsorship_list {
      margin-top: get-vw(0px);

      .sponsorship_item {
        margin: get-vw(20px);

        .content {
          .name {
            font-size: get-vw(16px);
            line-height: get-vw(20px);
          }
          .email {
            font-size: get-vw(14px);
            line-height: get-vw(20px);
            margin-right: get-vw(10px);
          }
          .phone {
            font-size: get-vw(14px);
            line-height: get-vw(20px);
          }
          .status {
            font-size: get-vw(14px);
            line-height: get-vw(22px);
          }
        }
      }
    }
  }

  .profile_dashboard_container {
    background: none;

    .profile_dashboard_wrapper {
      display: flex;
      flex-direction: row;

      .side-left {
        flex: 1;

        display: grid;
        grid-template-columns: repeat(2, 1fr);
        justify-content: space-between;
      }

      .side-right {
        flex: 2;
      }

      .profile-dashboard-stat {
        padding: 50px 30px;
        background: $white-color;
        border-radius: 10px;
        margin: 10px;

        .bloc_title_info {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;


          .profile-dashboard-stat-number {
            font-size: 40px;
            font-weight: 400;
            font-family: 'Inter', sans-serif;
          }

          .profile-dashboard-stat-text {
            font-size: 20px;
          }
        }
      }

      .dashboard_block_wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .dashboard_block_more_link {
          text-decoration: underline;
          color: $main-color;
          position: relative;
          top: inherit;
          right: inherit;

          font-size: 16px;
        }
      }
    }
  }

  #profilemenu_page_wrapper{
    &:before {
      display: none;
    }

    padding-top: 0;

    .user_picture{
      margin-bottom : get-vw(20px);
      .picture{
        overflow: hidden;
        width: get-vw(120px);
        height: get-vw(120px);

        border-radius : 100%;
        background-color : $form-upload-profil;
        margin: 0 auto get-vw(10px) auto;

        img{
          object-fit: cover;
          width: 100%;
          height: 100%;
        }
      }
      .name{
        color : $main-color;
        font-weight: bold;
        text-align: center;

        font-size : get-vw(20px)
      }
    }

    nav{
      a{
        display: block;
        background-color : $white-color;
        color : $main-color;

        height: get-vw(50px);
        line-height: get-vw(50px);
        margin-bottom : get-vw(10px);

        padding: 0 get-vw(15px);

        text-decoration: none;
        box-shadow: get-vw(-2px) get-vw(2px) get-vw(5px) $grey-color;

        border-radius: get-vw(5px);


        font-size : get-vw(12px)
      }
    }

  }


  #single_match_wrapper{

    padding-bottom: get-vw(80px);

    #match_header{
      position: absolute;
      right: 0;
      display: flex;
      flex-direction: row;
      z-index: 9;

      padding-top: 0;
      margin-top: 0;

      .cta.return {
        margin-right: auto;
      }

      > div {
        display: flex;
        flex-direction: row;
        gap: get-vw(5px);
      }
    }
    #cta_wrapper{
      position: fixed;
      bottom: 0;
      left: 0;

      width: 100%;
      padding: get-vw(20px) get-vw(40px);
    }

    .linking_step_wrapper {
      padding: get-vw(10px);
    }

  }

  #match_details {
    //margin-top: 50px;

    .content {
      font-size: 16px;

      > div{
        margin-bottom: get-vw(5px);
      }
    }
  }

  #community_category {
    margin: get-vw(20px) 0;

    .category_filters {
      display: flex;
      flex-direction: row;
      gap: get-vw(20px);

      .cta {
        //border-radius: get-vw(10px);
      }
    }
  }

  #community_wrapper {
    .cta {
      &.small {
        font-size: 1rem;
        line-height: 40px;
        padding: 0 20px;
      }
    }

    .searchbar_filter {
      margin-top: get-vw(10px);
      margin-bottom: get-vw(5px);
    }

    .content_block {
      margin-top: get-vw(20px);
    }
  }

  /* OTHER */
  .slick-dots {
    position: static;
  }


  .single_item_messages, #single_item_collab, #single_mission_collab, #single_help_collab, #single_switch_collab {
    background: $white-color;
    padding: 30px;
    border-radius: 20px;
    margin: 20px 0;
  }

  .content_header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .filterbar_wrapper {
      min-width: 600px;
    }
    .searchbar_filter {
      min-width: 600px;
    }
  }

  #addmission_form > form {
    display: flex;
    flex-direction: row;

    .side-right {
      position: relative;

      .side-right-fixed {
        position: fixed;
      }
      .cta_wrapper {
      }
    }
  }

  #page_matchs_wrapper {
    .mission_block_wrapper {
      display: grid;
      grid-template-columns: repeat(3, 1fr);

      .mission_block_item {
        margin: 10px;
      }
    }
  }

  #page_missions_wrapper {
    .mission_block_wrapper {
      display: grid;
      grid-template-columns: repeat(3, 1fr);

      .mission_block_item {
        margin: 10px;
      }
    }
  }

  #page_aides_wrapper {
    .help_block_wrapper {
      display: grid;
      grid-template-columns: repeat(3, 1fr);

      .help_block_item {
        margin: 10px;
      }
    }
  }

  .grid_block_wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);

    .block_item {
      margin: 10px;
    }
  }

  #page_echanges_wrapper {
    .switch_block_wrapper {
      display: grid;
      grid-template-columns: repeat(3, 1fr);

      .switch_block_item {
        margin: 10px;
      }
    }
  }

  .company_list {
    margin: 10px 0 30px 0;

    .company_item {
      padding: 10px;
      border-bottom: 1px solid $main-color;
      font-size: 16px;
      
      &:hover {
        cursor: pointer;
      }
    }
  }

  .footer-logout {
    bottom: inherit;
    top: 0;
    left: inherit;
    right: 0;
  }

  #community_cgu_wrapper {
    padding: 50px;
    background: $white-color;
  }

  #page_acts_wrapper {
    .content_header {
      margin-top: get-vw(10px);
    }
  }

  .act_block_item {
    background-color : $dashboard-block-bg;
    text-decoration: none;

    border-radius: get-vw(10px);
    border: get-vw(1px) solid $dashboard-block-border;

    padding: get-vw(15px) get-vw(20px);

    margin : get-vw(10px);

    .question_header_title {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: get-vw(5px);
      margin-bottom: get-vw(5px);

      .picture {
        width: get-vw(24px);
        height: get-vw(24px);
      }
    }

    .title{
      color : $main-color;
      margin-bottom : get-vw(2px);
      font-size: get-vw(12px);
      line-height: get-vw(16px);
      letter-spacing: get-vw(0.2px);
    }

    .date{
      color: $main-color;
      font-size: get-vw(8px);
      margin-bottom : get-vw(9px);

      span{
        color: $skills-color;
      }

    }

    .description{
      color: $main-color;
      font-size: get-vw(12px);
      line-height: get-vw(18px);

      margin-bottom: get-vw(10px);
    }

    .file {
      text-decoration: underline;
    }

    .cta_wrapper {
      position: absolute;
      top: get-vw(5px);
      right: get-vw(5px);
      width: 100%;

      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;

      gap: get-vw(5px);

      padding: get-vw(5px) get-vw(10px);

      .cta{
        font-size : 0;

        &.edit{
          background-image : url("../images/mobile/edit.svg");
          background-position: center;
          background-size : 100% auto;
          background-repeat: no-repeat;

          width: get-vw(20px);
          height: get-vw(20px);
        }

        &.delete{
          background-image : url("../images/mobile/delete_cross.svg");
          background-position: center;
          background-size : 100% auto;
          background-repeat: no-repeat;

          width: get-vw(20px);
          height: get-vw(20px);
        }
      }
    }
  }

  #setting_wrapper {
    h2 {
      font-size: get-vw(20px);
      margin-top: get-vw(30px);
    }

    p {
      font-size: 1.2rem;
    }

    .notification_block {
      padding: get-vw(10px);
      margin-top: get-vw(10px);

      th {
        font-size: get-vw(16px);
        line-height: get-vw(18px);
        color: $main-color;

        padding: get-vw(5px);

        &:first-of-type {
          font-size: get-vw(16px);
          width: 80%;
        }
      }

      td {
        &:nth-of-type(2), &:nth-of-type(3) {
          text-align: center;
        }

        padding-bottom: get-vw(10px);

        font-size: 1.2rem;
      }
    }

    .setting_inline_form {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-bottom: get-vw(10px);

      h4 {
        margin-bottom: 0;
      }
    }

    .setting_legal_link_wrapper {
      li {
        margin-bottom: get-vw(10px);
      }
    }
  }

  #email_wrapper, #phone_wrapper, #password_wrapper {
    max-width: 600px;
    background: $white-color;
    padding: 50px;
  }

  .linking_step_wrapper {
    .linking_step_item {

      margin: 20px 0;

      &:before {
        left: -20px;
      }

      .linking_step_item_status {
        font-size: get-vw(16px);
        line-height: get-vw(20px);
      }

      .linking_step_item_date {
        font-size: get-vw(14px);
        line-height: get-vw(18px);
      }
    }
  }
}